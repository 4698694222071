<template>
  <div class="div-container">
    <div
      v-if="
        evaluationCriteriasMacrosList.length > 0 ||
        evaluationCriteriasMicrosList.length > 0
      "
    >
      <div v-if="!is_collapsed">
        <div v-if="evaluationCriteriasMacrosList.length > 0">
          <div
            v-for="index in parseInt(length)"
            :key="index"
            class="evaluation-criterias-macros"
          >
            <span class="evaluation-criterias-macros-order">
              {{
                evaluationCriteriasMacrosList[index - 1]
                  ? evaluationCriteriasMacrosList[index - 1].order + "."
                  : ""
              }}
            </span>
            <SentenceContainer
              v-if="evaluationCriteriasMacrosList[index - 1]"
              class="text-class"
              v-b-tooltip.left.v-secondary.noninteractive="
                `
          ${$getVisibleNames(
            'teaching.evaluationcriteriamacro',
            true,
            'Criterio de Evaluación Macro'
          )}`
              "
              :Sentence="evaluationCriteriasMacrosList[index - 1]"
              :boundedTextContainer="true"
              :Large="Large"
            ></SentenceContainer>
          </div>
        </div>
        <div v-else-if="evaluationCriteriasMicrosList.length > 0">
          <div v-for="item in filterECMicro" :key="item.id">
            <div
              v-for="index in parseInt(length)"
              :key="index"
              class="evaluation-criterias-micros"
            >
              <span class="evaluation-criterias-micros-order">
                {{ item.options[index - 1] ? item.order + "." : ""
                }}{{
                  item.options[index - 1] ? item.options[index - 1].order : ""
                }}
              </span>
              <SentenceContainer
                v-if="item.options[index - 1]"
                class="text-class"
                v-b-tooltip.left.v-secondary.noninteractive="
                  `${$getVisibleNames(
                    'teaching.evaluationcriteriamicro',
                    true,
                    'Criterio de Evaluación Micro'
                  )}`
                "
                :Sentence="item.options[index - 1]"
                :boundedTextContainer="true"
                :Large="Large"
              ></SentenceContainer>
            </div>
          </div>
        </div>
      </div>
      <b-collapse :id="String(key_id)" class="mt-2 mb-3" v-if="is_collapsed">
        <div
          v-for="item in evaluationCriteriasMacrosList"
          :key="item.id"
          class="evaluation-criterias-macros"
        >
          <span class="evaluation-criterias-macros-order">
            {{ item ? item.order : "" }}.
          </span>
          <SentenceContainer
            class="text-class"
            v-b-tooltip.left.v-secondary.noninteractive="
              `
          ${$getVisibleNames(
            'teaching.evaluationcriteriamacro',
            true,
            'Criterio de Evaluación Macro'
          )}`
            "
            :Sentence="item"
            :boundedTextContainer="true"
            :Large="Large"
          ></SentenceContainer>
        </div>
        <div v-for="item in filterECMicro" :key="item.id">
          <div
            v-for="ec_micro in item.options"
            :key="ec_micro.id"
            class="evaluation-criterias-micros"
          >
            <span class="evaluation-criterias-micros-order">
              {{ item ? item.order : "" }}.{{ ec_micro ? ec_micro.order : "" }}
            </span>
            <SentenceContainer
              class="text-class"
              v-b-tooltip.left.v-secondary.noninteractive="
                `
                ${$getVisibleNames(
                  'teaching.evaluationcriteriamicro',
                  true,
                  'Criterio de Evaluación Micro'
                )}`
              "
              :Sentence="ec_micro"
              :boundedTextContainer="true"
              :Large="Large"
            ></SentenceContainer>
          </div>
        </div>
      </b-collapse>
      <span v-b-toggle="String(key_id)" class="mb-0">
        <b-button class="btn-transparent" @click="is_collapsed = !is_collapsed">
          <b-icon
            v-if="!is_collapsed"
            icon="chevron-down"
            v-b-tooltip.left.hover.v-secondary.noninteractive="
              'Presione para desplegar todos los criterios seleccionados.'
            "
          ></b-icon>
          <b-icon
            v-else
            icon="chevron-up"
            v-b-tooltip.left.hover.v-secondary.noninteractive="
              'Presione para reducir los criterios seleccionados.'
            "
            :class="{ 'collapse-icons': !is_collapsed }"
          >
          </b-icon>
        </b-button>
      </span>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "EvaluationCriterias",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    key_id: {
      type: Number,
      required: true,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    ECMacro: {
      type: Array,
      required: true,
    },
    ECMicro: {
      type: Array,
      required: true,
    },
    Large: {
      type: Number,
      default: 100,
    },
    length: {
      type: Number,
      default: 1,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_collapsed: this.isCollapsed,
    };
  },
  computed: {
    ...mapGetters({
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      section_evaluations: names.SECTION_EVALUATIONS,
      studyUnits: names.STUDY_UNITS,
      evaluations: names.EVALUATIONS,
      sections: names.SECTIONS,
      matters: names.MATTERS,
    }),
    evaluationCriteriasMacrosList() {
      return this.evaluationCriteriasMacros
        .filter((x) => this.ECMacro.includes(x.id))
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    evaluationCriteriasMicrosList() {
      return this.evaluationCriteriasMicros
        .filter((x) => this.ECMicro.includes(x.id))
        .sort(function (a, b) {
          if (a.study_unit > b.study_unit) return 1;
          if (a.study_unit < b.study_unit) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnits.forEach((study_unit) => {
        const evaluation_criterias = this.evaluationCriteriasMicrosList.filter(
          (x) => x.study_unit == study_unit.id
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: study_unit.name,
            order: study_unit.order,
            options: this.evaluationCriteriasMicrosList
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
    filter_sections() {
      if (!this.evaluation) return null;
      return this.sections.filter((x) =>
        this.evaluation.sections.includes(x.id)
      );
    },
    filter_egress_profiles_matter() {
      let list = [];
      if (!this.filter_sections) return list;
      this.filter_sections.forEach((section) => {
        list.push(section.egress_profile_matter);
      });
      return this.egress_profiles_matters.filter((x) => list.includes(x.id));
    },

    studyUnitsList() {
      let list = [];
      this.evaluationCriteriasMicrosList.forEach((x) => {
        list.push(x.study_unit);
      });
      const unique = [...new Set(list)];
      return this.studyUnits.filter((x) => unique.includes(x.id));
    },
  },
  created() {},
};
</script>
<style scoped>
.evaluation-criterias-macros,
.evaluation-criterias-micros {
  display: flex;
}
.evaluation-criterias-macros-order,
.evaluation-criterias-micros-order {
  font-size: 11pt;
  transform: translateY(-2%);
}
.evaluation-criterias-macros-order {
  margin-right: 0.5em;
}
.text-class:hover {
  background: #dddd;
  border-radius: 4px;
}
.btn-transparent {
  background: transparent;
  border: none;
  color: black !important;
  margin: 5px;
  padding: 2px;
  font-size: var(--thirth-font-size);
  text-decoration: none;
  /* float: right; */
  margin-top: -2%;
}
.collapse-icons {
  display: none;
}
#collapse-1 {
  z-index: 1;
  transition: 0.3s ease-out;
}
#collapse-1::after {
  display: none;
  transition: 0.3s;
}
.div-container {
  text-align: center !important;
}
</style>